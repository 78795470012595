import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/blog_layout.js";
import YouTube from "react-youtube";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "european-flight-data-analysis-w-data-chimp-for-tidy-tuesday-2022-07-12"
    }}><a parentName="h1" {...{
        "href": "#european-flight-data-analysis-w-data-chimp-for-tidy-tuesday-2022-07-12"
      }}>{`European Flight Data Analysis w/ data chimp (for Tidy Tuesday 2022-07-12)`}</a></h1>
    <p>{`I used data chimp to help me analyze european flight data as a part of the Tidy Tuesday challenge.
This screencast is just 20 minutes of me wrangling data and coding up some visualizations with data chimp's help.
Check it out if you want to see data chimp in action.`}</p>
    <YouTube videoId="9qGyU6BEfe4" mdxType="YouTube" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      